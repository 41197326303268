<template>
  <el-dialog
    v-model="dialogVisible"
    :title="formData?.code ? '编辑类型' : '新增类型'"
    width="500px"
    :before-close="handleClose"
  >
    <el-form ref="formRef" :model="form" :rules="rules" label-width="100px" style="padding: 20px">
      <el-form-item label="类型名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入类型名称" />
      </el-form-item>

      <el-form-item label="所属类型" prop="parentCode">
        <el-select v-model="form.parentCode" placeholder="请选择所属类型" style="width: 100%">
          <el-option v-for="item in storeTypeList" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>

      <el-form-item label="备注">
        <el-input v-model="form.remarks" type="textarea" placeholder="请输入备注" :rows="3" />
      </el-form-item>

      <el-form-item label="启用状态">
        <el-switch v-model="form.isEnable" />
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ref, defineComponent, reactive, watch, onMounted } from 'vue'
import API from '../../../service/api'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'PackageTypeForm',
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => null,
    },
    storeCode: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue', 'success'],
  setup(props, { emit }) {
    const formRef = ref(null)
    const dialogVisible = ref(props.modelValue)
    const storeTypeList = ref([])
    // 表单数据
    const form = reactive({
      name: props.formData?.name || '',
      parentCode: props.formData?.parentCode || '',
      remarks: props.formData?.remarks || '',
      isEnable: props.formData?.isEnable == 1 ?? true,
    })
    onMounted(() => {
      getStoreTypeList()
    })

    // 表单校验规则
    const rules = {
      name: [
        { required: true, message: '请输入类型名称', trigger: 'blur' },
        { max: 20, message: '长度不能超过20个字符', trigger: 'blur' },
      ],
      parentCode: [{ required: true, message: '请选择所属类型', trigger: 'change' }],
    }

    const getStoreTypeList = async () => {
      const res = await API.getStoreTypeList()
      if (res.code == 200) {
        storeTypeList.value = [...res.data]
      }
    }

    // 监听弹窗显示状态
    watch(
      () => props.modelValue,
      val => {
        dialogVisible.value = val
      },
    )

    watch(
      () => dialogVisible.value,
      val => {
        emit('update:modelValue', val)
      },
    )

    // 关闭弹窗
    const handleClose = () => {
      dialogVisible.value = false
    }

    // 提交表单
    const handleSubmit = async () => {
      if (!formRef.value) return
      await formRef.value.validate(async (valid, fields) => {
        try {
          if (valid) {
            let res
            const data = {
              ...form,
              isEnable: form.isEnable ? 1 : 2,
              storeCode: props.storeCode,
              parentName: storeTypeList.value.find(item => item.code == form.parentCode)?.name,
              level: storeTypeList.value.find(item => item.code == form.parentCode)?.level,
            }
            if (props.formData?.code) {
              //修改
              data.code = props.formData.code
              res = await API.updateBoxType({ ...data })
            } else {
              //新增
              res = await API.addBoxType({ ...data })
            }
            if (res.code == 200) {
              ElMessage.success('操作成功')
              emit('success')
            } else {
              ElMessage.error(res.msg)
            }
          }
        } catch (error) {
          console.log(error)
        }
      })
    }

    return {
      dialogVisible,
      form,
      formRef,
      rules,
      handleClose,
      handleSubmit,
      storeTypeList,
    }
  },
})
</script>
